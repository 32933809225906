<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-22 09:11:33
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-09-10 15:01:28
 * @FilePath: \qcwp-vue\packages\web\src\components\layout\nav\search-nav.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script setup lang='ts'>
import { homePath } from '@qcwp/common'
const { isDesktop } = useDevice()
const route = useRoute()
</script>

<template>
  <div class="flex items-center sticky top-0 m-auto px-3 h-12 sm:h-16 max-w-300 w-full">
    <div flex items-center h-12 sm="h-16 py-1" flex-shrink-0>
      <nuxt-link class="logo h-10 w-22 flex-shrink-0 lt-md:pr-3 tablet:mr-3" md="w-24 h-10" :to="homePath()">
        <img h-full w-full object-contain src="/logo.png" alt="">
      </nuxt-link>
      <template v-if="isDesktop">
        <layout-nav-menu v-if="$route.name !== 'doArticle_search'" block w-auto lt-md="w-0 hidden ml-0" />
        <layout-nav-seriesSearch v-else :is-to-series="$route.name === 'doArticle_search'" />
      </template>
    </div>
    <div class="setting flex-grow w-0 flex items-center  justify-end">
      <layout-nav-seriesSearch v-if="!route.fullPath.includes('doArticle_search') && isDesktop" :is-to-series="false" />
      <layout-nav-search v-else-if="!route.fullPath.includes('doArticle_search') " class="mr-3 md:mr-5 " />
      <div class="flex items-center justify-end md:w-90 md:ml-5">
        <client-only>
          <layout-nav-user class="md:mr-sm" />
          <utils-miniprogram-qrcode class="md:mr-sm" lt-md="hidden" />
          <utils-mobile-qrcode lt-md="hidden" />
        </client-only>
      </div>
    </div>
  </div>
</template>
