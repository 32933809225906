import { useStorage } from '@vueuse/core'
import {
  carHomePath, dailyPath, homePath, loginPath, mediaHomePath,
  newsHangQingPath,
  newsHomePath,
  newsTopicPath,
  reputationHomePath,
  userPath,
  videoHomePath,
} from '@qcwp/common'
import { useAuth } from '~~/src/store'

export const MENUS = [
  {
    url: homePath(),
    label: '首页',
    type: 'all',
  },
  {
    url: mediaHomePath(),
    label: '网评号',
    type: 'all',
  },
  {
    url: newsHomePath(),
    label: '资讯',
    type: 'all',
  },
  {
    url: videoHomePath(),
    label: '视频',
    type: 'all',
  },
  {
    url: newsHangQingPath(),
    label: '行情导购',
    type: 'more',
  },
  {
    url: newsTopicPath(),
    label: '专题大全',
    type: 'more',
  },
  {
    url: dailyPath(),
    label: '精华日报',
    type: 'more',
  },
  {
    url: carHomePath(),
    label: '汽车',
    type: 'all',
  },
  {
    url: reputationHomePath(),
    label: '口碑',
    type: 'more',
  },
  {
    url: userPath(),
    label: '个人中心',
    type: 'more',
  },
  {
    url: userPath(),
    label: '搜索',
    type: 'more',
  },
]
export function useTabbarComponent() {
  const router = useRouter()
  const route = useRoute()
  const authStore = useAuth()

  const TABBAR_KEYS = {
    home: homePath(),
    news: newsHomePath(),
    media: mediaHomePath(),
    car: carHomePath(),
    video: videoHomePath(),
    user: userPath(),
  }
  const popupShow = ref(false)
  const active = useStorage('WEB_TABBAR_KEY', 'home')

  function beforeChange(name: string) {
    if (name === 'more') {
      popupShow.value = true
      return false
    }

    if (name === 'user') {
      authStore.IS_LOGIN ? window.open(TABBAR_KEYS[name as keyof typeof TABBAR_KEYS]) : window.open(loginPath({ redirect: userPath() }))
      return false
    }
    if (Object.keys(TABBAR_KEYS).includes(name))
      router.push(TABBAR_KEYS[name as keyof typeof TABBAR_KEYS])
    else
      return false

    return true
  }
  // 页面跳转后修改当前激活项
  function initActive() {
    const values = Object.values(TABBAR_KEYS) as string[]
    if (values.includes(route.path)) {
      for (const v in TABBAR_KEYS) {
        if (route.path === TABBAR_KEYS[v as keyof typeof TABBAR_KEYS])
          active.value = v
      }
    }
    else {
      active.value = ''
    }
  }

  return {
    popupShow,
    active,
    beforeChange,
    initActive,
  }
}
