import { searchPath } from '@qcwp/common'

function renderCustomHeader() {
  return h('div', { class: 'flex items-center justify-between px-2 py-1' }, [
    h('div', { class: 'text-default text-center' }, '搜索历史'),
    h('div', { class: 'text-primary text-center' }, '清空'),
  ])
}

// TODO:UP:history
export function useSearchHistory() {
  const historyOptions = [
    {
      type: 'render',
      key: 'header',
      render: renderCustomHeader,
    },
    {
      key: 'header-divider',
      type: 'divider',
    },
    {
      label: '布朗酒店，伦敦布朗酒店，伦敦布朗酒店，伦敦布朗酒店，伦敦布朗酒店，伦敦布朗酒店，伦敦',
      key: 'brown\'s hotel, london',
    },

  ]
  function handleSelect(value: string) {
  // eslint-disable-next-line no-console
    console.log('value', value)
  }

  return {
    historyOptions,
    handleSelect,
  }
}

export function useSearchComponent() {
  const router = useRouter()
  const searchKeyWord = ref('')
  const searchRef = ref()
  const isFocus = ref(false)
  function searchBlurHandle() {
  // 如果有history下拉时为了响应点击下拉
    setTimeout(() => {
      isFocus.value = false
    }, 10)
  }
  function autoFocusHandle() {
    isFocus.value = true
    nextTick(() => {
      searchRef.value && searchRef.value.focus()
    })
  }
  function searchHandle() {
    router.push(searchPath(searchKeyWord.value))
  }

  return {
    searchKeyWord,
    searchRef,
    isFocus,
    searchBlurHandle,
    autoFocusHandle,
    searchHandle,
  }
}
