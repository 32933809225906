<script setup lang='ts'>
const popupShow = ref(false)
</script>

<template>
  <span>
    <span text-6 @click="popupShow = true">
      <i i-heroicons-solid:menu-alt-3 />
    </span>
    <layout-tabbar-popup v-model:show="popupShow" type="all" />
  </span>
</template>
