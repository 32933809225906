import { newsTypePath } from '@qcwp/common'
import type { ArticleClass, ArticleColumnParams, ArticleColumnResponse } from '~~/src/server/modules/article/type'
import { getArticleColumn } from '~~/src/server/modules/article'
/**
 *  获取资讯栏目
 * @param params ArticleColumnParams
 * @param options Partial<Record<'beforeArticleClasses' | 'afterArticleClasses', ArticleClass[]> & { formatArticleColumn?: (data: ArticleColumnResponse[]) => ArticleClass[] }>
 */
export function LogicColumn(
  params: ArticleColumnParams,
  options?: Partial<Record<'beforeArticleClasses' | 'afterArticleClasses', ArticleClass[]> & { formatArticleColumn?: (data: ArticleColumnResponse[]) => ArticleClass[] }>,
) {
  const menuList = ref<ArticleClass[]>([...options?.beforeArticleClasses ?? [], ...options?.afterArticleClasses ?? []])
  const format = options?.formatArticleColumn || formatArticleColumn
  async function getData() {
    try {
      const { data } = await getArticleColumn(params)
      menuList.value = format(data)
    }
    catch (error) {
      recordError(error)
    }
  }
  async function setupFn() {
    const asyncData = await useAsyncData(`COLUMN_${qs.stringify(params)}`, () => getArticleColumn(params))
    const { data = [] } = useNuxtAsyncHandle(asyncData)
    menuList.value = format(data)
  }
  function formatArticleColumn(data: ArticleColumnResponse[]): ArticleClass[] {
    let cols: ArticleClass[] = []

    cols = cols.concat(options?.beforeArticleClasses ?? [])
    for (const item of data) {
      cols.push({
        ...item,
        id: item.id,
        label: item.columnName,
        url: newsTypePath(item.id),
      })
    }
    cols = cols.concat(options?.afterArticleClasses ?? [])
    return cols
  }
  return {
    menuList,
    getData,
    setupFn,
  }
}
