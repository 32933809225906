<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-22 09:11:33
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-09-06 13:37:50
 * @FilePath: \qcwp-vue\packages\web\src\components\layout\nav\search.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script setup lang='ts'>
import { searchPath } from '@qcwp/common'
import { useSearchComponent } from './hooks/search'

const {
  searchKeyWord,
  searchHandle,
  searchRef,
  isFocus,
  searchBlurHandle,
} = useSearchComponent()
</script>

<template>
  <!-- pc -->
  <div hidden md:block v-bind="$attrs">
    <n-input
      ref="searchRef"
      v-model:value="searchKeyWord"
      :style="{
        '--n-padding-right': '0',
        '--n-font-size': '0.885rem',
        '--n-height': '2.5rem',
        '--n-border-radius': '0.1875rem',
      }" type="text"
      placeholder="搜索从此刻开始..."
      @focus="isFocus = true"
      @blur="searchBlurHandle"
      @keyup.enter="searchHandle"
    >
      <template #suffix>
        <n-button type="primary" h-10 w-14 @click="searchHandle">
          <div i-carbon-search text-4 title="搜索" />
        </n-button>
      </template>
    </n-input>
  </div>
  <!-- mobile -->
  <NuxtLink
    :to="searchPath()"
    class="flex-grow px-2 text-title-500 py-4 md:hidden block h-9 flex items-center text-3.5 bg-gray-100 rounded-md"
    title="搜索"
    v-bind="$attrs"
  >
    <i i-eva:search-fill mr-2 />
    <span whitespace-nowrap truncate inline-block>搜索从此刻开始...</span>
  </NuxtLink>
</template>
