<script setup lang='ts'>
import {
  carHomePath,
  // carImgHomePath,
  carZhHomePath,
  dealerFindPath,
  homePath,
  liveHomePath,
  mediaHomePath,
  newsHangQingPath,
  newsHomePath,
  newsTopicPath,
  newsTypePath,
  reputationHomePath,
  videoHomePath,
} from '@qcwp/common'

// import { NuxtLink } from '#components'
import { checkRouteIsActive } from '~~/src/common/function/url'
import { LogicColumn } from '~~/src/logic/column'

const carOptions = ref([
  {
    key: 'find-dealer',
    type: 'render',
    render: () => h('div', { class: 'px-1' }, [
      h('a', { class: 'dropdown-item', href: dealerFindPath() }, { default: () => '经销商查询' }),
    ]),
  },
  {
    key: 'zhaoche-zh',
    type: 'render',
    render: () => h('div', { class: 'px-1' }, [
      h('a', { class: 'dropdown-item', href: carZhHomePath() }, { default: () => '综合找车' }),
    ]),
  },
])

const newsOptions = ref([
  {
    key: 'news-ztChannel',
    type: 'render',
    render: () => h('div', { class: 'px-1' }, [
      h('a', { class: 'dropdown-item', href: newsTopicPath() }, { default: () => '专题大全' }),
    ]),
  },
  {
    key: 'news-hangqing',
    type: 'render',
    render: () => h('div', { class: 'px-1' }, [
      h('a', { class: 'dropdown-item', href: newsHangQingPath() }, { default: () => '行情导购' }),
    ]),
  },
])

async function getColumns() {
  const { menuList: articleClasses, setupFn } = LogicColumn({ columnType: 1, level: 1 })

  try {
    await setupFn()
    for (const item of articleClasses.value) {
      if (['6'].includes(item.id))
        continue
      newsOptions.value.push({
        key: item.id,
        type: 'render',
        render: () => h('div', { class: 'px-1' }, [
          h('a', { class: 'dropdown-item', href: newsTypePath(item.id) }, { default: () => item.columnName }),
        ]),
      })
    }
  }
  catch (err) {

  }
}
getColumns()
</script>

<template>
  <div overflow-x-auto h-full w-full flex items-center justify-center>
    <ul class="text-4.5 h-full flex items-center" lt-tablet="font-500 text-4">
      <li>
        <NuxtLink :to="homePath()">
          首页
        </NuxtLink>
      </li>
      <li>
        <NuxtLink :to="mediaHomePath()" :class="{ 'page-active': checkRouteIsActive(/^\/(article\/daily)/) }">
          网评号
        </NuxtLink>
      </li>
      <li>
        <n-dropdown trigger="hover" display-directive="show" :options="newsOptions">
          <NuxtLink :to="newsHomePath()" :class="{ 'page-active': checkRouteIsActive(/^\/(news|article\/articleType)/) }">
            资讯
          </NuxtLink>
        </n-dropdown>
      </li>
      <li>
        <NuxtLink :to="videoHomePath()" :class="{ 'page-active': checkRouteIsActive(/^\/video/) }">
          视频
        </NuxtLink>
      </li>
      <li>
        <NuxtLink :to="liveHomePath()" :class="{ 'page-active': checkRouteIsActive(/^\/live/) }">
          直播
        </NuxtLink>
      </li>
      <li>
        <n-dropdown trigger="hover" display-directive="show" :options="carOptions">
          <NuxtLink :to="carHomePath()" :class="{ 'page-active': checkRouteIsActive(/^\/(car|series)/) }">
            汽车
          </NuxtLink>
        </n-dropdown>
      </li>
      <li>
        <NuxtLink :to="reputationHomePath()" :class="{ 'page-active': checkRouteIsActive(/^\/koubei/) }">
          口碑
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
li {
  --uno: h-full flex-shrink-0 grid place-content-center mr-3;
}

a {
  --uno: px-4 py-1.5;
}

.page-active,
[aria-current="page"],
a:hover {
  box-shadow: inset 0 5px 5px 0 rgb(0 0 0 / 10%);

  --uno: text-white  rounded-sm bg-blue-5;
}
</style>
