import { getHostPagePath, homePath } from '@qcwp/common'
import { isClient } from '@qcwp/utils'
/**
 * Tips: setup中使用
 * 返回上一级页面
 */
export function goBackPage() {
  if (!isClient)
    return
  const pageLen = history.length
  const router = useRouter()

  if (router) {
    if (pageLen > 2)
      router.back()
    else
      router.replace(homePath())
  }
  else {
    if (pageLen > 2)
      history.back()
    else
      window.location.href = getHostPagePath('web', homePath())
  }
}
