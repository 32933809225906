<script lang="ts" setup>

</script>

<template>
  <main>
    <div class="fixed right-5 bottom-10 grid grid-cols-1 gap-3 z-99" lt-md="bottom-20 right-3">
      <LazyUtilsUp />
      <LazyUtilsFeedback />
    </div>
    <slot />
  </main>
</template>
