<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-09-06 17:16:29
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-09-10 14:57:10
 * @FilePath: \qcwp-vue\packages\web\src\components\layout\nav\seriesSearch.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script lang='ts' setup>
import { searchUserPath } from '@qcwp/common'
import type { searchSeriesItem } from '~/server/modules/car/types'
import { carApi } from '~/server'

withDefaults(defineProps<{
  isToSeries?: boolean
}>(), {
  isToSeries: true,
})

const route = useRoute()
const { isDesktop } = useDevice()

const keyword = ref('')
const router = useRouter()
const searchFocus = ref(true)
function search(k?: string | undefined) {
  // console.log('🚀 ~ search ~ k:', k)
  const text = k || keyword.value
  if (!text)
    return vantToast('请输入搜索条件')

  searchFocus.value = false
  router.push(searchUserPath({ keyword: text }))
}

route.name === 'doArticle_search' && watch(() => route.query.keyword, (val) => {
  if (keyword.value === val)
    return
  keyword.value = val as string
}, {
  immediate: true,
})

watchDebounced(keyword, (val) => {
  if (!val)
    return
  getSeriesList(val)
})

const seriesList = ref<searchSeriesItem[]>([])
async function getSeriesList(str: string) {
  const { data } = await carApi.searchSeriesSuggestApi({
    vendorSeriesName: str,
  })

  seriesList.value = data || []
}

function blurHandler() {
  setTimeout(() => searchFocus.value = false, 200)
}

const isSearchPath = computed(() => route.name === 'doArticle_search')
</script>

<template>
  <n-input-group class="w-full" flex>
    <div class="relative w-full">
      <n-input v-model:value="keyword" placeholder="搜索从此刻开始..." :autofocus="true" size="large" :style="isSearchPath ? { width: isDesktop ? '400px' : '100%' } : { }" @blur="blurHandler" @focus="searchFocus = true" @keyup.enter="search(undefined)" />
      <div v-show="searchFocus && keyword && seriesList.length" class="absolute top-10 right-0 pr-3 w-full flex items-center bg-white z-10  p-3  ">
        <n-scrollbar class="max-h-50 p-3 text-4 leading-loose cursor-pointer " trigger="none">
          <div v-for="series in seriesList" :key="series.seriesId" class="my-1 flex justify-between" for="series in seriesList">
            <span class="line-clamp-1" @click="search(series.vendorSeriesName)">{{ series.vendorSeriesName }}</span>
            <NuxtLink v-if="isToSeries" :to="`series/${series.seriesId}/`" class=" text-3 text-gray">
              直达车系
            </NuxtLink>
          </div>
        </n-scrollbar>
      <!-- <div class="absolute top-0 right-0 p-1 cursor-pointer" @click="searchFocus = false">
        <i class="i-carbon-close text-5 " />
      </div> -->
      </div>
    </div>
    <n-button v-if="isSearchPath" size="large" class="h-10" style="border-radius: 0 10px 10px 0" type="primary" @click="search(undefined)">
      搜索
    </n-button>
    <n-button v-else type="primary" h-10 w-14 @click="search(undefined)">
      <div i-carbon-search text-4 title="搜索" />
    </n-button>
  </n-input-group>
</template>

<style lang='scss' scoped></style>
