<script setup lang='ts'>
const props = withDefaults(defineProps<{
  title?: string
  type?: 'search' | 'back'
  shadow?: boolean
}>(), {
  title: '',
  shadow: false,
  type: 'search',
})

const { isDesktop } = useDevice()
</script>

<template>
  <div
    v-bind="$attrs" class="default-header transition"
    :class="[shadow ? 'sm:shadow-md' : 'border-b-1 border-gray-50'] "
  >
    <div v-if="props.type === 'search'" :class="[props.type === 'search' ? '' : 'lt-md:hidden']">
      <layout-nav-search-nav />
    </div>
    <div v-if="!isDesktop && props.type === 'back'" h-full w-full :class="[props.type === 'back' ? 'md:hidden' : 'hidden']">
      <layout-nav-back-nav :title="props.title" />
    </div>
  </div>
  <!-- fixed占位符 -->
  <div class="default-header-placeholder" />
</template>

<style lang='scss' scoped>
.default-header {
  // bg-opacity-60  backdrop-blur-md
  --uno: fixed w-full top-0 z-999;
  --uno: bg-white;
  --uno: h-12 sm:h-16;
  --uno: flex flex-col justify-between;

  &-content {
    --uno: flex items-center;
    --uno: m-auto px-3 h-12 sm:h-16;
    --uno: max-w-300 w-full;
  }

  &-placeholder {
    --uno: w-full top-0 z-1 h-12 sm:h-16;
  }
}
</style>
