<script setup lang='ts'>
import { MENUS } from './hooks/index'

const props = withDefaults(defineProps<{
  show?: boolean
  type?: 'all' | 'more'
}>(), {
  show: false,
})
const emits = defineEmits({
  'update:show': (show: boolean) => typeof show === 'boolean',
})
function updateShowHandle(show: boolean) {
  emits('update:show', show)
}
const SHOW_MENUS = computed(() => {
  return props.type === 'all'
    ? MENUS
    : MENUS.filter(v => v.type === 'more')
})
</script>

<template>
  <van-popup
    :show="show"
    safe-area-inset-bottom
    round
    position="bottom"
    :style="{ height: '400px' }"
    @update:show="updateShowHandle"
  >
    <div h-full flex="~ col" bg-gray-100>
      <div flex-grow bg-white px-3>
        <div text-center font-black py-4 text-4>
          全部菜单
        </div>
        <div grid="~ cols-3" gap-3>
          <NuxtLink v-for="v, i in SHOW_MENUS" :key="i" :to="v.url" class="menu-item">
            {{ v.label }}
          </NuxtLink>
        </div>
      </div>
      <div class="menu-button" @click="updateShowHandle(false)">
        关闭
      </div>
    </div>
  </van-popup>
</template>

<style lang='scss' scoped>
.menu-item {
  --uno: text-center bg-gray-100 text-title-600 py-2.5;
}

.menu-button {
  --uno: bg-white flex-shrink-0 h-13 leading-13 text-center text-title-600 text-4 mt-2;
  --uno: active:bg-gray-100 transition;
}
</style>
