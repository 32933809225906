<script setup lang='ts'>
import { MEDIA_MD, goAnyPage, imageTransform, loginPath, userPath } from '@qcwp/common'
import { NAvatar } from 'naive-ui'
import { isClient } from '@qcwp/utils'
import { useAuth } from '~~/src/store'
const authStore = useAuth()
const { IS_LOGIN, USER_HEAD, USER_NAME } = storeToRefs(authStore)
const { isMobileOrTablet } = useDevice()
const route = useRoute()
const options = ref([
  {
    type: 'render',
    key: 'profile',
    render: () => h('div',
      {
        class: 'flex items-center text-3.5 hover:text-primary py-2 px-3 border-b-1 border-gray-200 cursor-pointer',
        onClick: () => goAnyPage(userPath(), 'user', true),
      }, [
        h(NAvatar, {
          class: 'flex-shrink-0 mr-2',
          round: true,
          src: USER_HEAD.value ? imageTransform(USER_HEAD.value, { format: 'webp' }) : '/noAvatar.jpg',
        }),
        h('span', { }, { default: () => USER_NAME.value || '还未设置用户名' }),
      ]),
  },
  {
    key: 'logout',
    type: 'render',
    render: () => h('div', { class: 'flex items-center py-2 px-3 cursor-pointer hover:text-primary', onClick: () => { authStore.logout() } }, [
      h('i', { class: 'i-lucide:log-out text-5 text-gray-500 mr-2' }),
      h('span', {}, { default: () => '退出登录' }),
    ]),
  },
])
</script>

<template>
  <template v-if="IS_LOGIN">
    <ClientOnly>
      <n-dropdown v-if="!isMobileOrTablet || (isClient && MEDIA_MD)" trigger="hover" :options="options">
        <NuxtLink target="_blank" no-rel :to="userPath()" class="flex items-center" v-bind="$attrs">
          <NAvatar size="large" flex-shrink-0 border="1 gray-100" round :src="USER_HEAD ? imageTransform(USER_HEAD as any, { format: 'webp' }) : '/noAvatar.jpg'" />
        </NuxtLink>
      </n-dropdown>
      <NuxtLink v-else target="_blank" no-rel :to="userPath()" flex items-center>
        <NAvatar alt="头像" flex-shrink-0 round :src="USER_HEAD ? imageTransform(USER_HEAD as any, { format: 'webp' }) : '/noAvatar.jpg'" />
      </NuxtLink>
    </ClientOnly>
  </template>
  <template v-else-if="route.name !== 'login'">
    <NuxtLink :to="loginPath()">
      <n-button type="primary" ghost>
        登录/注册
      </n-button>
    </NuxtLink>
  </template>
</template>
